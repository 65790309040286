import {shallowRef, watch, ref, getCurrentInstance} from 'vue';
import {
    useRoute,
    useRuntimeConfig,
    useState,
    useServerSeoMeta,
    navigateTo
} from '#app';
import {capitalizeFirstLetter, snakeToCamel} from '../lib/functions';
import {load} from '../composables/page';
import {ConcreteComponent} from '@vue/runtime-core';
import {PageData} from '../lib/interfaces/model/PageData';
import {useServerHeadSafe} from "../.nuxt/imports";
import {useServerHead} from "@unhead/vue";

export function onPageLoad() {
    const route = useRoute();
    const realComponent: string | Boolean | ConcreteComponent = shallowRef(false);
    const mainPageData = ref<PageData | null | string>(null);
    const redirect = ref<boolean | string>(false);
    const mainHeadData = ref({});
    const context = getCurrentInstance();

    watch(() => route.path, async () => {
        await loadPage();
    });

    watch(() => mainPageData.value, () => {
        if (mainPageData.value && typeof mainPageData.value !== 'string') {
            useState('page').value = mainPageData.value.page;
        }
    });

    watch(() => redirect.value, () => {
        if (redirect.value !== false && typeof redirect.value === 'string') {
            navigateTo(redirect.value);
        }
    });


    const loadPage = async () => {
        if (isValid(route.path.slice(1))) {
            try {
                const {pageData, sections, pageLayoutFilename, headData} = await load(useRuntimeConfig().public, {
                    path: route.path.slice(1),
                    query: route.query as { [k: string]: string },
                });

                if (pageLayoutFilename && context && typeof context.appContext.components[capitalizeFirstLetter(snakeToCamel(pageLayoutFilename))] === 'object') {
                    mainPageData.value = pageData;
                    realComponent.value = context.appContext.components[capitalizeFirstLetter(snakeToCamel(pageLayoutFilename))];
                    mainHeadData.value = headData;
                    if (!process.client) {
                        useServerSeoMeta(headData.meta);
                    }
                } else if (pageData && pageData.redirect) {
                    redirect.value = pageData.redirect;
                } else if (context && typeof context.appContext.components['Page404'] === 'object') {
                    realComponent.value = context.appContext.components['Page404'];
                    mainPageData.value = '404';
                }
            } catch (e) {
                realComponent.value = context.appContext.components['Page404'];
                mainPageData.value = '404';
                return;
            }
        } else {
            realComponent.value = context.appContext.components['Page404'];
            mainPageData.value = '404';
        }
    };

    const isValid = (path: string) => {
        return path.indexOf('.') === -1;
    };


    return {loadPage, realComponent, mainPageData, mainHeadData};
}
