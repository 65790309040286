import {PageData} from "../interfaces/model/PageData";
import {PageDataHolder} from "../interfaces/model/PageDataHolder";

export default class EmptyPageData implements PageData {
  holders: Array<PageDataHolder>;
  metaTags: {
    og: {
      type: "",
      description: "",
      title: "",
      app_id: "",
      locale: "",
      image: "",
      url: ""
    },
    custom: {
      description: null,
      title: null,
      robots: null,
      keywords: null,
      author: null,
      viewport: null
    }
    meta: {
      description: "",
      title: "",
      robots: "",
      keywords: "",
      author: "",
      viewport: ""
    }
  };
  page: {
    title: "",
    id: -1,
    link: "",
    hidden: true
  };
  layout: {
    id: -1,
    title: "",
    destination: "",
    type: "page"
  };
  accommodation: undefined;
  accommodationSubObject: undefined;
  client: undefined;
  sections: []

}
