import {PageData} from "./interfaces/model/PageData";
import {PageDataMetaTags} from "./interfaces/model/PageDataMetaTags";
import {stripTags} from "./functions";
import EmptyPageData from "./models/EmptyPageData";
import Client from "./models/Client";
import {TyrionResourceImageType} from "./enums/TyrionResourceImageType";
import Accommodation from "./models/Accommodation";
import AccommodationUnit from "./models/AccommodationUnit";
import Image from "./models/Image";

const mutationDefaults = {
    'sk': {
        'domain': "HladamChatu.sk",
        'about': "Váš jedinečný portál pre nájom chát na dovolenky a výlety do prírody. Vychutnajte si pohodlie chatovej dovolenky"
    },
    'cz': {
        'domain': "HledamChatu.cz",
        'about': "Váš jedinečný portál pro pronájem chat na dovolenou a výlety do přírody. Užijte si pohodlí chatové dovolené"
    },
    'pl': {
        'domain': "Meganoclegi.pl",
        'about': "Twój wyjątkowy portal do wynajmu chat na wakacje i wycieczki do przyrody. Ciesz się komfortem wakacji w chacie"
    },
    'hu': {
        'domain': "Utaz.hu",
        'about': "Az Ön egyedülálló portálja nyaralók és természetjáró kirándulások bérléséhez. Élvezze a nyaralás kényelmét egy nyaralóban"
    },
    'default': {
        'domain': "HladamChatu.sk",
        'about': "Váš jedinečný portál pre nájom chát na dovolenky a výlety do prírody. Vychutnajte si pohodlie chatovej dovolenky"
    },
}

export default class Head {

    protected headData: Object | null = null;

    public switchMutation(langCode: string, key: string) {
        // @ts-ignore
        return mutationDefaults[langCode] && mutationDefaults[langCode][key] ? mutationDefaults[langCode][key] : (mutationDefaults['default'][key] ? mutationDefaults['default'][key] : "");
    }

    public generateTyrionHead(pageData: PageData | EmptyPageData, config) {

        const applyCustomIfExist = (key: string, metaTags: PageDataMetaTags, thirdValue?: string | null | undefined, fourth?: string | undefined): string => {
            if (metaTags.custom !== undefined && metaTags.meta !== undefined && metaTags.custom[key] && metaTags.custom[key].length > 0) {
                return metaTags.custom[key];
                // @ts-ignore
            } else if (metaTags.meta !== undefined && metaTags.meta[key] && metaTags.meta[key].length > 0) {
                // @ts-ignore
                return metaTags.meta[key];
            } else if (thirdValue && thirdValue.length > 0) {
                return thirdValue;
            } else if (fourth && fourth.length > 0) {
                return fourth;
            } else {
                return "";
            }
        };

        if (this.headData) {
            return this.headData;
        }

        if (pageData instanceof EmptyPageData || pageData.page === undefined) {
            return;
        } else {
            const webUrl = config.WEB_URL;
            const apiUrl = config.API_URL;
            const langCode = pageData.language as string;
            if (pageData.accommodation !== undefined) {
                const accommodation = (new Accommodation()).fromObject(pageData.accommodation) as Accommodation;
                let desc = stripTags(accommodation.translations?.[langCode]?.description?.['-1'] ?? accommodation.description).replace(/(?:\r\n|\r|\n)/g, "");
                try {
                    desc = (desc.length > 5) ? desc : accommodation.getGeneratedDescription();
                } catch (e) {
                    desc = "";
                }

                if (pageData.accommodationSubObject !== undefined) {
                    const accommodationSubObject = (new AccommodationUnit()).fromObject(pageData.accommodationSubObject) as AccommodationUnit;
                    let desc_sub = stripTags(accommodationSubObject.description).replace(/(?:\r\n|\r|\n)/g, "");
                    desc_sub = (desc_sub.length > 5) ? desc_sub : desc;
                    const title = (accommodation.title.length > 20 ? accommodation.title.substring(0, 25) : accommodation.title) + " " + accommodationSubObject.title.substring(0, 30) + " | " + this.switchMutation(langCode, 'domain');
                    this.headData = {
                        title: title,
                        meta:
                            {
                                "title": title,
                                "description": (desc_sub.length > 150) ? (desc_sub.substring(0, 150) + "...") : desc_sub,
                                "og:description": (desc_sub.length > 150) ? (desc_sub.substring(0, 150) + "...") : desc_sub,
                                "robots": (accommodation.hidden || accommodation.old || accommodation.removed) ? "noindex" : "index, follow",
                                "og:title": title,
                                "og:image:alt": title,
                                "og:image": accommodationSubObject.hasImage(false) ? accommodationSubObject.getTopImage().getTyrionSrc(TyrionResourceImageType.ThumbM, undefined, apiUrl) : `/img/og_blank.jpg?v=2`,
                                "og:image:width": accommodationSubObject.hasImage(false) ? Math.round(accommodationSubObject.getTopImage().width) : `1500`,
                                "og:image:height": accommodationSubObject.hasImage(false) ? Math.round(accommodationSubObject.getTopImage().height) : `457`,
                                "keywords": [accommodation.location && accommodation.location.city ? accommodation.location.city.name : undefined, accommodation.resort ? accommodation.resort.name : undefined, this.switchMutation(langCode, 'domain')].filter(i => i && i.length > 0).join(", "),
                                "author": "MaVaSto s.r.o.",
                                "fb:app_id": `2089833381070490`,
                                "twitter:card": "summary",
                                "og:type": "article",
                            }
                    };
                } else {

                    let accTitle = (accommodation.translations?.[langCode]?.title?.['-1'] ?? accommodation.title);
                    accTitle = accTitle.length > 60 ? accTitle.substring(0, 70) : accTitle;
                    if (accommodation.location && accommodation.location.tourist_region) {
                        accTitle += " - " + (
                            accommodation?.location?.tourist_region?.langtitle?.[langCode] ??
                            accommodation?.location?.tourist_region?.title ??
                            ""
                        );
                    }
                    if (accommodation.location && accommodation.location.city) {
                        accTitle += (" - " + accommodation.location.city.title);
                    }
                    this.headData = {
                        "title": accTitle + " | " + this.switchMutation(langCode, 'domain'),
                        meta: {
                            "title": accTitle + " | " + this.switchMutation(langCode, 'domain'),
                            "description": (desc.length > 150) ? (desc.substring(0, 150) + "...") : desc,
                            "og:description": (desc.length > 150) ? (desc.substring(0, 150) + "...") : desc,
                            "robots": (accommodation.hidden || accommodation.old || accommodation.removed) ? "noindex" : "index, follow",
                            "og:title": accTitle,
                            "og:image:alt": accTitle,
                            "og:image": accommodation.hasImage() ? accommodation.getTopImage().getTyrionSrc(TyrionResourceImageType.ThumbM, undefined, apiUrl) : `/img/og_blank.jpg?v=2`,
                            "og:image:width": accommodation.hasImage() ? Math.round(accommodation.getTopImage().seoWidth('thumb_m')) : `1500`,
                            "og:image:height": accommodation.hasImage() ? Math.round(accommodation.getTopImage().seoHeight('thumb_m')) : `457`,
                            "keywords": [accommodation.location && accommodation.location.city ? accommodation.location.city.title : undefined, accommodation.resort ? accommodation.resort.name : undefined, this.switchMutation(langCode, 'domain'), accommodation.category]
                                .filter(i => i && i.length > 0).join(", "),
                            "author": "MaVaSto s.r.o.",
                            "fb:app_id": `2089833381070490`,
                            "twitter:card": "summary",
                            "og:type": "article",
                        },

                    }
                }
            } else {
                let pageImage = pageData.page.images[0] ?? null;
                if (pageImage && pageImage?.id > 0) {
                    pageImage = (new Image()).fromObject(pageImage).getTyrionSrc(TyrionResourceImageType.ThumbM, undefined, apiUrl);
                }

                this.headData = {
                    "title": applyCustomIfExist("title", pageData.metaTags, pageData.page.title) + " | " + this.switchMutation(langCode, 'domain'),
                    meta: {
                        "title": applyCustomIfExist("title", pageData.metaTags, pageData.page.title) + " | " + this.switchMutation(langCode, 'domain'),
                        "description": applyCustomIfExist("description", pageData.metaTags, this.switchMutation(langCode, 'about')) + " | " + this.switchMutation(langCode, 'domain'),
                        "og:description": applyCustomIfExist("description", pageData.metaTags, this.switchMutation(langCode, 'about')) + " | " + this.switchMutation(langCode, 'domain'),
                        "robots": pageData.page.hidden ? "noindex" : "index, follow",
                        "og:title": applyCustomIfExist("title", pageData.metaTags, pageData.page.title),
                        "keywords": applyCustomIfExist("keywords", pageData.metaTags),
                        "author": applyCustomIfExist("author", pageData.metaTags, null, "MaVaSto s.r.o."),
                        "og:image": (pageData.metaTags && pageData.metaTags.og && pageData.metaTags.og.image) ? pageData.metaTags.og.image : (pageImage ? pageImage : `${webUrl}/img/og_blank.jpg?v=2`),
                        "og:image:alt": applyCustomIfExist("title", pageData.metaTags, pageData.page.title) + " | " + this.switchMutation(langCode, 'domain'),
                        "fb:app_id": `2089833381070490`
                    }
                }
            }

            if (pageData.metaTags.canonicals) {
                if (pageData.metaTags.canonicals.some(c => c.hreflang === pageData.language)) {
                    this.headData.link = [
                        {
                            'rel': 'canonical',
                            'href': pageData.metaTags.canonicals.find(c => c.hreflang === pageData.language)?.link
                        }
                    ];
                }
                pageData.metaTags.canonicals.forEach(alternate => {
                    this.headData.link.push({
                        rel: 'alternate',
                        hreflang: alternate.hreflang,
                        href: alternate.link,
                    })
                })
            }

            return this.headData;
        }
    }

}
