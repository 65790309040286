import {PageData} from "~/lib/interfaces/model/PageData";
import {useFetch, useNuxtApp, useState} from "#app";
import Head from "~/lib/Head";
import {clearObserverObj} from "~/lib/functions";
import {IApplicationNuxtPageData} from "~/lib/interfaces/IApplicationNuxtPageData";
import EmptyPageData from "~/lib/models/EmptyPageData";
import locales from "~/lib/data/locales.json";
import {getCurrentUser} from "./auth/useAuthUser";
import {ClientFavoriteItem} from "../lib/models/ClientFavoriteItem";

export async function load(config: Object, route: { path: string, query: { [k: string]: string } }) : Promise<{pageData: PageData, sections: any, pageLayoutFilename: string, headData: {[k:string]: any}}> {
    const {$tyrionApi} = useNuxtApp();
    let dynComponent = "";
    const loggedClient = getCurrentUser();
    const emptyIndexData: IApplicationNuxtPageData = {
        pageData: new EmptyPageData(),
        sections: [],
        pageLayoutFilename: "default"
    };
    const pageLink = route.path === "" ? "index" : route.path; // Page from top url after domain

    if (process.client) {

        if (loggedClient && loggedClient.favorites && loggedClient.favorites.constructor === Array && loggedClient.favorites.length > 0 && useState('favorites', () => []).value.length !== loggedClient.favorites.length) {
            useState("favorites").value = loggedClient.favorites.map((item: ClientFavoriteItem) => {
                return {
                    id: Number(item.id_accommodation)
                }
            });
        }
    }

    return $tyrionApi.getTyrionUrl(`api3/full_page/${pageLink}.sle3.json`, {cacheTime: (60 * 5)}).then(data => {
        if (data && data.status === 'success') {
            const pageData = data.data as PageData;
            dynComponent = pageData.layout.destination.replace('Custom/View/Layout/Pages/', '').replace('.php', '');

            const headData = {
                htmlAttrs: {lang: pageLink.substring(0, 2)},
                ...(pageData.metaTags ? (new Head()).generateTyrionHead(pageData, config) : {}),
            };

            //useState('page').value = pageData.page;
            //useState('accommodation').value = pageData.accommodation;
            return {
                pageData,
                sections: [],
                pageLayoutFilename: dynComponent,
                headData
            }
        } else if (data && data.data && data.data.redirect && data.data.redirect.length > 0) {
            const resData = clearObserverObj(emptyIndexData);
            resData.pageData.redirect = data.data.redirect
            return resData;
        } else if (data && data.status === 'error') {
            return clearObserverObj(emptyIndexData);
        }


    })


}
